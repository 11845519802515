
































































































































  import { PaginatedList } from "@/api/models/common/paginatedList";
  import { Component, Vue, Watch } from "vue-property-decorator";
  import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts'
  import CreateAction from "./_CreateAction.vue";
  import { VBPopover, BPagination,BDropdown, BTable, BCol, BButton } from 'bootstrap-vue'
  import { PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";
  import { DouleurPsychique } from "@/api/models/options/douleursPsychiques/douleursPsychiques";
  import DetailsPassageInfirmerie from "./_DetailsPassageInfirmerie.vue";
import { DouleurPhysique } from '../../../api/models/options/douleursPhysiques/douleursPhysiques';
import { DroitsEnum } from "@/api/models/enums/droitsEnum";

  @Component({
    components: {
      CreateAction,
      BPagination,
      BDropdown,
      BTable,
      BCol,
      BButton,
      DetailsPassageInfirmerie
    },
    directives: {
      'b-popover' : VBPopover
    }
  })
  export default class ListePassageInfirmerie extends Vue {
    droitsEnum = DroitsEnum;
    
    isSortDirDesc = false;
    sortBy='date';

    currentPage: any =  1;
    perPage: any = 5;

    selectedPassageInfirmerieId: string = "0";

    displayAddPassageInfirmerie = false
    
    listPassageInfirmerie: PaginatedList<PassageInfirmerie> = new PaginatedList<PassageInfirmerie>();
    listDouleursPsychiques: PaginatedList<DouleurPsychique> = new PaginatedList<DouleurPsychique>();
    listDouleursPhysiques: PaginatedList<DouleurPhysique> = new PaginatedList<DouleurPhysique>();

    tableColumns = [
      { key: 'datePassageInfirmerie', sortable: true, disable: false },
      { key: 'horaire', sortable: false, disable: false},
      { key: 'createur', label: "Créateur", sortable: false, disable: false },
      { key: 'typePassageLibelle', label: "Type de passage", sortable: false, disable: false },
      { key: 'listActionsRealisees', label: "Action(s) réalisée(s)", sortable: false, disable: false },
      { key: 'douleurs', label: "Douleurs Présentes", sortable: false, disable: false },
      { key: 'details', sortable: false, disable: false },
      { key: 'actions', sortable: false, disable: false }
    ];
    
    async created() {
      await this.loadListPassageInfirmerie({
        pageNumber: this.currentPage,
        pageSize: 5
      });
      await this.loadDouleursPsychiques();
      await this.loadDouleursPhysiques();
    }

    @Watch('currentPage')
    async currentPageChange(){
      await this.loadListPassageInfirmerie({
        pageNumber: this.currentPage,
        pageSize: this.perPage
      })
    }

    async addPassageInfirmerie(){
      this.displayAddPassageInfirmerie = true;
      var passageInfirmerieForm = (this.$refs['createPassageInfirmerieForm'] as any);
      if(passageInfirmerieForm)
      {
       passageInfirmerieForm.resetModel();
       passageInfirmerieForm.editable = true;
      }
    }

    resetDisplayAddPassageInfirmerie(){
      this.displayAddPassageInfirmerie = false
    }

    getUniqueIds(list: Array<{ id: number,douleurPhysiqueId:number }>): number[] {
      console.log(list);
      const uniqueIds = new Set<number>();

      list.forEach((element) => {
        uniqueIds.add(element.douleurPhysiqueId);
      });

      var unique = Array.from(uniqueIds);

      return Array.from(uniqueIds);
      }



    async createCopy(passageInfirmerieId: string, editable: boolean){
      setTimeout(async () => {
        await (this.$refs['createPassageInfirmerieForm'] as any).createFromCopy(passageInfirmerieId, editable)
      }, 500);
    }
  
    async loadListPassageInfirmerie(params: any) {
      await this.$http.ressifnet.passagesInfirmerie.paginatedList(this.$route.params.id, params?.pageNumber, params?.pageSize).then(
        (response: PaginatedList<PassageInfirmerie>) => {
          this.listPassageInfirmerie = response;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des actions",
          });
        }
      );
    }

    deletePassageInfirmerie(passageInfirmerieId: string) {
      this.$bvModal
        .msgBoxConfirm("Etes-vous sûr de vouloir supprimer le passage infirmerie ?", {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value === true) {
            await this.confirmDelete(passageInfirmerieId);
          } else {
            return;
          }
        });
    }

    async confirmDelete(passageInfirmerieId: string) {
      await this.$http.ressifnet.passagesInfirmerie
        .delete(this.$route.params.id, passageInfirmerieId)
        .then(
          (response: any) => {
            successAlert.fire({
              title: "Suppression d\'un passage infirmerie",
              text: "Suppression effectuée avec succès"
            })
            this.loadListPassageInfirmerie({
              pageNumber: this.currentPage,
              pageSize: this.perPage
            });
          },
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          }
        );
    }

    getDouleursPsychiquesLibelle(douleursPsychiquesId: number){
        var douleursPsychiques = this.listDouleursPsychiques.items.filter(x => x.id == douleursPsychiquesId)[0]
        return douleursPsychiques?.libelle
    }

    getDouleurPhysiqueLibelle(douleurPhysiqueId: number){
        var douleurPhysique = this.listDouleursPhysiques.items.filter(x => x.id == douleurPhysiqueId)[0]
        return douleurPhysique?.libelle
    }

    async loadDouleursPsychiques(){
      await this.$http.ressifnet.douleursPsychiques.paginatedList(1, 50).then(
          (response: PaginatedList<PassageInfirmerie>) => {
            this.listDouleursPsychiques = response;
          },
          (error: any) => {
          errorAlert.fire({
              title: "Erreur lors du chargement des douleurs psychiques",
          });
        }
      );
    }

    async loadDouleursPhysiques(){
      await this.$http.ressifnet.douleursPhysiques.paginatedList(1, 50).then(
          (response: PaginatedList<PassageInfirmerie>) => {
            this.listDouleursPhysiques = response;
          },
          (error: any) => {
          errorAlert.fire({
              title: "Erreur lors du chargement des douleurs physiques",
          });
        }
      );
    }
  }
